<template>
  <div id="dashboard">
    <md-toolbar>
      <h3 class="md-title" style="flex: 1">DGVO Formular</h3>
      <md-button class="md-primary md-raised" @click="logout"
        >Abmelden</md-button
      >
    </md-toolbar>
    <div class="container">
      <md-table v-model="submissions" v-if="!isLoading">
        <md-table-toolbar>
          <div class="md-toolbar-section-start">
            <h3 class="md-title">Einträge</h3>
          </div>
          <div class="md-toolbar-section-end">
            <md-button class="md-primary" @click="download"
              >Herunterladen</md-button
            >
          </div>
        </md-table-toolbar>
        <md-table-empty-state md-label="Keine Einträge gefunden" />
        <md-table-row slot="md-table-row" slot-scope="{ item }">
          <md-table-cell md-label="Erstellt">{{
            new Date(item.created_at).toLocaleDateString()
          }}</md-table-cell>
          <md-table-cell md-label="E-Mail">{{
            item.remax_email
          }}</md-table-cell>
          <md-table-cell md-label="Vorname">{{
            item.first_name
          }}</md-table-cell>
          <md-table-cell md-label="Nachname">{{
            item.last_name
          }}</md-table-cell>
          <md-table-cell md-label="Unternehmen">{{
            item.company_name
          }}</md-table-cell>
          <md-table-cell md-label="IP-Addresse">{{
            item.ip_address
          }}</md-table-cell>
          <md-table-cell md-label="Anzahl Versicherungen">{{
            item.insurances.length
          }}</md-table-cell>
          <md-table-cell md-label="Maklervereinbarung">
            <md-button
              class="md-primary"
              style="margin: 0"
              v-if="item.termination_authorisation_path"
              @click="downloadAttachment(item.termination_authorisation_path)"
            >
              herunterladen
            </md-button>
          </md-table-cell>
        </md-table-row>
      </md-table>
    </div>
  </div>
</template>

<script>
import { API_URI_BASE } from "../config";
import axios from "axios";
import { parse } from "json2csv";

export default {
  name: "Dashboard",
  data() {
    return {
      submissions: [
        {
          name: "test",
          email: "test@test.com",
          ip: "1.1.1.1.1",
        },
      ],
      isLoading: true,
    };
  },
  async created() {
    await this.getSubmissions();
  },
  methods: {
    downloadAttachment(path) {
      let url = `${window.location.origin}/api/download?file_name=${path}`;
      window.open(url, "_blank");
    },
    async logout() {
      try {
        let config = {
          method: "GET",
          url: `${API_URI_BASE}/auth/logout`,
        };
        await axios(config);
        await this.$router.push({ name: "Login" });
      } catch (e) {
        localStorage.clear();
        await this.$router.push({ name: "Login" });
      }
    },
    async getSubmissions() {
      try {
        let config = {
          method: "GET",
          url: `${API_URI_BASE}/submission/all`,
          withCredentials: true,
        };
        let response = await axios(config);
        this.submissions = response.data.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );
        this.isLoading = false;
      } catch (e) {
        console.error(e);
      }
    },
    async download() {
      try {
        let config = {
          method: "GET",
          url: `${API_URI_BASE}/submission/download`,
          withCredentials: true,
        };
        let response = await axios(config);
        console.log(response);
        this.generateCSV(response.data);
        this.isLoading = false;
      } catch (e) {
        console.error(e);
      }
    },
    generateCSV(data) {
      const fields = [
        "created_at",
        "ip_address",
        "company_name",
        "first_name",
        "last_name",
        "remax_email",
        "street",
        "house_number",
        "location",
        "postal_code",
        "risk_location_street",
        "risk_location_house_number",
        "risk_location",
        "risk_location_postal_code",
        "had_previous_damage",
        "has_accepted_broker_authorisation",
        "has_accepted_consultation_documentation",
        "has_accepted_contact_consent",
        "has_accepted_data_protection",
        "insurance_1_insurance_type",
        "insurance_1_insurance_begin",
        "insurance_1_insurance_sum",
        "insurance_1_insurance_revenue",
        "insurance_1_previous_insurance",
        "insurance_1_previous_insurance_company",
        "insurance_1_previous_insurance_contract_number",
        "insurance_1_previous_insurance_terminate",
        "insurance_1_previous_insurance_termination_date",
        "insurance_2_insurance_type",
        "insurance_2_insurance_begin",
        "insurance_2_insurance_sum",
        "insurance_2_insurance_revenue",
        "insurance_2_previous_insurance",
        "insurance_2_previous_insurance_company",
        "insurance_2_previous_insurance_contract_number",
        "insurance_2_previous_insurance_terminate",
        "insurance_2_previous_insurance_termination_date",
        "insurance_3_insurance_type",
        "insurance_3_insurance_begin",
        "insurance_3_insurance_sum",
        "insurance_3_insurance_revenue",
        "insurance_3_previous_insurance",
        "insurance_3_previous_insurance_company",
        "insurance_3_previous_insurance_contract_number",
        "insurance_3_previous_insurance_terminate",
        "insurance_3_previous_insurance_termination_date",
      ];
      const opts = { fields };

      try {
        const csv = parse(data, opts);
        const url_encoded_csv = encodeURIComponent(csv);
        const mimetype = "data:text/csv;charset=utf-8,";
        const export_url = mimetype + url_encoded_csv;
        this.createDownloadLink(export_url);
      } catch (e) {
        console.error(e);
      }
    },
    createDownloadLink(csvUri) {
      let el = document.createElement("a");
      el.download = "dgvo-export.csv";
      el.href = csvUri;
      el.target = "_blank";
      el.click();
    },
  },
};
</script>

<style scoped lang="scss">
@import "../styles/main";

#dashboard {
  height: 100vh;
  background-color: #f4f4f4;
  display: flex;
  flex-direction: column;
}

.container {
  padding: 24px 16px;
  flex: 1;
  overflow: auto;
}
</style>
